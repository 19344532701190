import React, {useState, useContext} from 'react';
import {Context} from '../../utils/context'
import RenderIf from "../../utils/renderIf";

export default function Notice({notice}){
    const {update, remove} = useContext(Context);
    const [editMode, setEditMode] = useState(false);
    const [value, seValue] = useState(notice.title);

    const selectNotice = (e) => {
        update(notice.id, {checked : e.target.checked})
    };

    const save = () => {
        const success = update(notice.id, {title: value});

        if(success) {
            setEditMode(false)
        }
    };

    const keyDownHandler = (e) => {
        if(e.key === 'Enter' && value.trim() !== '') {
            save(value);
        }
    }


    return (
        <div className="note">
            <div className="note__checkbox">
                <input type="checkbox" onChange={selectNotice} checked={notice.checked}/>
            </div>

            <RenderIf condition={editMode}>
                <input
                    type="text"
                    onChange={e => seValue(e.target.value)}
                    onKeyDown={keyDownHandler}
                    value={value}
                    className="input"
                />
            </RenderIf>

            <RenderIf condition={!editMode}>
                <p className="note__title">{value}</p>
            </RenderIf>

            <div className="note__buttons">

                <RenderIf condition={editMode}>
                    <button
                        className="btn --success"
                        disabled={value.trim() === ''}
                        onClick={save}
                    >
                        Сохранить
                    </button>
                </RenderIf>

                <RenderIf condition={!editMode}>
                    <button
                        className="btn --success"
                        onClick={setEditMode.bind(null, !editMode)}
                    >
                        Редактировать
                    </button>
                </RenderIf>


                <button className="btn --danger" onClick={remove.bind(null, [notice.id])}>Удалить</button>
            </div>
        </div>
    )

}