import React, {useState} from 'react'

export default function NoticeList(notices = []) {
    const [list, setList] = useState(notices);

    const remove = (noticesId) => {
        if(typeof noticesId === 'object' && Array.isArray(noticesId)) {
            // Удалить из масива list элемент если он есть масиве noticesId
            setList(list.filter(({id})=> !noticesId.includes(id)))
        }
    };

    const update = (id, updatingData) => {
        if(updatingData.hasOwnProperty('title')) {
            if(updatingData.title.trim() === '') {
                return false;
            }
        }

        setList(list.map(i => {
            if(i.id === id) {
                return {
                    ...i,
                    ...updatingData
                }
            }
            return i;
        }));

        return true;
    };

    const add = (title) => {
        if(title.trim() !== '') {
            setList([...list, {title, id : +new Date(), checked : false}])
        }
    };

    return {
        list,
        remove,
        update,
        add,
    }
}