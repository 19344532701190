import React, {useState} from 'react';


export default function NoticeHeader({addNotice}) {
    const [value, setValue] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();
        addNotice(value);
        setValue('')
    };

    return (
        <div className="notice-header">
            <h2 className="notice-header__title">Добавьте новую заметку</h2>

            <form action="" id="form" onSubmit={submitHandler}>
                <input className="input" type="text" value={value} onChange={(e) => setValue(e.target.value)}/>
                <button type="submit" className="btn --success" disabled={value.trim() === ''}>Добавить</button>
            </form>
        </div>
    )
}