import React from 'react';
import './styles/index.scss'
import NoticeComponent from "./components/notice";

function App() {
  return (
    <div className="container">
      <NoticeComponent/>
    </div>
  );
}

export default App;
