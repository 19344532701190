import React from 'react';
import useNotices from "../../hooks/useNotices";
import NoticeHeader from "./noticeHeader";
import NoticeList from "./noticeList";
import {Context} from '../../utils/context'
import RenderIf from '../../utils/renderIf'


export default function NoticeComponent() {
    const {add, list, remove, update} = useNotices();

    const selected = list.filter(i => i.checked).map(i => i.id);

    return (
        <Context.Provider value={{remove, update}}>
            <NoticeHeader addNotice={add}/>

            <div className="actions">
                <RenderIf condition={selected?.length}>
                    <button className="btn --danger" onClick={remove.bind(null,selected)}>Удалить все выбранные</button>
                </RenderIf>
            </div>

            <NoticeList list={list}/>
        </Context.Provider>
    )
}